import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=60c8545a&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsHamburger: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/icons/hamburger.vue').default,IconsXMark: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/icons/x-mark.vue').default,Button: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/formulate/Button.vue').default,NavList: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/base/NavList.vue').default,IconsChevron: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/icons/chevron.vue').default,MobileMenu: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/base/MobileMenu.vue').default})
