
import catalog from '~/graphql/queries/catalog';

export default {
  apollo: {
    catalog: {
      query: catalog,
      variables() {
        return {
          supplier: this.supplier || this.$auth.user.supplier_uuid,
        };
      },
      result({data}) {
        this.catalog = data?.catalog?.exsellItems || [];
      },
    },
  },
  props: {
    value: {
      type: [Array, String],
      required: true,
      default: '',
    },
    label: {
      type: String,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    supplier: {
      type: String,
      default: '',
    },
    excluded: {
      type: Array,
      default: () => ([]),
    },
    alternative: {
      type: Boolean,
      default: false,
    },
    metadata: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    open: false,
    products: [],
    searchProducts: '',
    notYetAvailable: false,
    notYetAvailableList: '',
    newMetadata: {},
    validationErrors: [],
    globalDiscountPercentage: 0,
    globalDiscountValue: 0,
  }),
  computed: {
    options() {
      return (this.catalog || []).filter((product) => {
        if (!this.searchProducts) {
          return true;
        }

        const brand = product.brand.toLowerCase().includes(this.searchProducts.toLowerCase());
        const sku = product.sku.includes(this.searchProducts);
        const name = product.name.toLowerCase().includes(this.searchProducts.toLowerCase());

        return brand || sku || name;
      }).map(product => ({
        value: product.sku,
        label: `${product.sku} - ${product.name} - ${product.brand}`,
        disabled: this.excluded.includes(product.sku),
      }));
    },
    productList() {
      return Array.isArray(this.products) ? this.products : [this.products];
    },
    hasMissingPackshots() {
      return !!(this.catalog || []).find(product => !product.packshot);
    },
  },
  watch: {
    value() {
      this.products = this.value;
    },
    products() {
      this.$emit('input', this.products);
      this.updateMetadata();
    },
    notYetAvailableList() {
      const items = this.notYetAvailableList.split(',').filter((product) => {
        return product.replace(/\s/g, '').length !== 0;
      });
      this.$emit('input', this.max === 1 ? items[0] : items);
    },
  },
  mounted() {
    if (this.value) {
      this.products = this.value;
    }

    if (this.metadata) {
      this.newMetadata = {};
      this.newMetadata = JSON.parse(JSON.stringify(this.metadata));
    }
  },
  methods: {
    updateGlobalDiscounts() {
      Object.entries(this.newMetadata).forEach((val) => {
        const meta = JSON.parse(JSON.stringify(this.newMetadata));
        meta[val[0]].discount_percentage = this.globalDiscountPercentage;
        meta[val[0]].discount_value = this.globalDiscountValue;

        this.newMetadata = meta;
      });

      this.updateMetadata();
    },
    updateMetadata() {
      if (this.max <= 1) {
        return;
      }

      const meta = JSON.parse(JSON.stringify(this.newMetadata));
      Object.entries(meta).forEach((val) => {
        if (!this.products.includes(val[0])) {
          delete meta[val[0]];
        }
      });

      this.products.forEach((product) => {
        if (!meta[product]) {
          meta[product] = {
            print: false,
            discount_percentage: 0,
            discount_value: 0,
          };
        }
      });

      this.newMetadata = meta;

      this.sendMetadataToForm();
    },
    sendMetadataToForm() {
      this.$emit('update-metadata', this.newMetadata);
    },
    remove(id) {
      this.products = this.products.filter(product => product !== id);
      delete this.newMetadata[id];
    },
    productDetail(id) {
      const product = (this.catalog || []).find(product => product.sku === id);

      return product ? `${product.sku} - ${product.name} - ${product.brand}` : id;
    },
    close() {
      this.validationErrors = [];
      if (!this.alternative) {
        this.open = false;
        return;
      }

      if (!Object.entries(this.newMetadata).filter(i => i[1].print).length) {
        this.validationErrors.push('Er moet minimaal 1 product worden afgedrukt.');
      } else {
        this.open = false;
      }
    },
  },
};
