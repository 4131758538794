import {page as pageQuery} from '~/graphql/queries/page';
import {settings as settingsQuery} from '~/graphql/queries/settings';

export const state = () => ({
  release: process.env.RELEASE,
  settings: [],
  page: {seo: {}, template: {}},
});

export const actions = {
  async nuxtServerInit({dispatch}) {
    try {
      await dispatch('storeDispatchFunc');
    } catch (e) {
      console.error(e);
    }
  },

  async storeDispatchFunc({commit}) {
    const [settings] = await Promise.all([
      this.app.apolloProvider.defaultClient.query({
        query: settingsQuery,
      }),
    ]);

    commit('SET_SETTINGS', settings.data ? settings.data.settings : []);
  },

  async getPage({commit}, slug = 'home') {
    try {
      const {
        data: {page},
      } = await this.app.apolloProvider.defaultClient.query({
        query: pageQuery,
        fetchPolicy: 'no-cache',
        variables: {
          segments: slug === '' ? 'home' : slug,
        },
      });

      if (!page) {
        const e = new Error('Page not found.');
        e.statusCode = 410;
        throw e;
      }

      commit('SET_PAGE', page);
      commit('breadcrumbs/SET_TITLE', page.title);
    } catch (e) {
      if (e.statusCode === 410) {
        throw e;
      }
      console.error(e);
    }
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
};
