const middleware = {}

middleware['group-redirects'] = require('../middleware/group-redirects.js')
middleware['group-redirects'] = middleware['group-redirects'].default || middleware['group-redirects']

middleware['gtm'] = require('../middleware/gtm.js')
middleware['gtm'] = middleware['gtm'].default || middleware['gtm']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['scope'] = require('../middleware/scope.js')
middleware['scope'] = middleware['scope'].default || middleware['scope']

export default middleware
