export const ArticlePicker = () => import('../../components/campaigns/wizard/ArticlePicker.vue' /* webpackChunkName: "components/article-picker" */).then(c => wrapFunctional(c.default || c))
export const CampaignWizard = () => import('../../components/campaigns/wizard/CampaignWizard.vue' /* webpackChunkName: "components/campaign-wizard" */).then(c => wrapFunctional(c.default || c))
export const ImageUpload = () => import('../../components/campaigns/wizard/ImageUpload.vue' /* webpackChunkName: "components/image-upload" */).then(c => wrapFunctional(c.default || c))
export const SelectableCard = () => import('../../components/campaigns/wizard/SelectableCard.vue' /* webpackChunkName: "components/selectable-card" */).then(c => wrapFunctional(c.default || c))
export const IncompleteCampaignsAlert = () => import('../../components/alerts/IncompleteCampaignsAlert.vue' /* webpackChunkName: "components/incomplete-campaigns-alert" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/base/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const BarChart = () => import('../../components/base/BarChart.vue' /* webpackChunkName: "components/bar-chart" */).then(c => wrapFunctional(c.default || c))
export const BooleanDisplay = () => import('../../components/base/BooleanDisplay.vue' /* webpackChunkName: "components/boolean-display" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/base/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CalendarSchedule = () => import('../../components/base/CalendarSchedule.vue' /* webpackChunkName: "components/calendar-schedule" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/base/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CardApplication = () => import('../../components/base/CardApplication.vue' /* webpackChunkName: "components/card-application" */).then(c => wrapFunctional(c.default || c))
export const CardResource = () => import('../../components/base/CardResource.vue' /* webpackChunkName: "components/card-resource" */).then(c => wrapFunctional(c.default || c))
export const CardSmall = () => import('../../components/base/CardSmall.vue' /* webpackChunkName: "components/card-small" */).then(c => wrapFunctional(c.default || c))
export const CardTable = () => import('../../components/base/CardTable.vue' /* webpackChunkName: "components/card-table" */).then(c => wrapFunctional(c.default || c))
export const ChartLegendItem = () => import('../../components/base/ChartLegendItem.vue' /* webpackChunkName: "components/chart-legend-item" */).then(c => wrapFunctional(c.default || c))
export const DescriptionList = () => import('../../components/base/DescriptionList.vue' /* webpackChunkName: "components/description-list" */).then(c => wrapFunctional(c.default || c))
export const DiscountedPrice = () => import('../../components/base/DiscountedPrice.vue' /* webpackChunkName: "components/discounted-price" */).then(c => wrapFunctional(c.default || c))
export const FilterList = () => import('../../components/base/FilterList.vue' /* webpackChunkName: "components/filter-list" */).then(c => wrapFunctional(c.default || c))
export const LineChart = () => import('../../components/base/LineChart.vue' /* webpackChunkName: "components/line-chart" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/base/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const MobileMenu = () => import('../../components/base/MobileMenu.vue' /* webpackChunkName: "components/mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuItem = () => import('../../components/base/MobileMenuItem.vue' /* webpackChunkName: "components/mobile-menu-item" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuSubmenu = () => import('../../components/base/MobileMenuSubmenu.vue' /* webpackChunkName: "components/mobile-menu-submenu" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NavList = () => import('../../components/base/NavList.vue' /* webpackChunkName: "components/nav-list" */).then(c => wrapFunctional(c.default || c))
export const PageAdvanced = () => import('../../components/base/PageAdvanced.vue' /* webpackChunkName: "components/page-advanced" */).then(c => wrapFunctional(c.default || c))
export const PageDefault = () => import('../../components/base/PageDefault.vue' /* webpackChunkName: "components/page-default" */).then(c => wrapFunctional(c.default || c))
export const PageGrid = () => import('../../components/base/PageGrid.vue' /* webpackChunkName: "components/page-grid" */).then(c => wrapFunctional(c.default || c))
export const PageIntro = () => import('../../components/base/PageIntro.vue' /* webpackChunkName: "components/page-intro" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/base/PageLoader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/base/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PieChart = () => import('../../components/base/PieChart.vue' /* webpackChunkName: "components/pie-chart" */).then(c => wrapFunctional(c.default || c))
export const ScrollProgress = () => import('../../components/base/ScrollProgress.vue' /* webpackChunkName: "components/scroll-progress" */).then(c => wrapFunctional(c.default || c))
export const Widget = () => import('../../components/base/Widget.vue' /* webpackChunkName: "components/widget" */).then(c => wrapFunctional(c.default || c))
export const InsightsLost = () => import('../../components/buying/InsightsLost.vue' /* webpackChunkName: "components/insights-lost" */).then(c => wrapFunctional(c.default || c))
export const InsightsRecurring = () => import('../../components/buying/InsightsRecurring.vue' /* webpackChunkName: "components/insights-recurring" */).then(c => wrapFunctional(c.default || c))
export const TableSources = () => import('../../components/buying/TableSources.vue' /* webpackChunkName: "components/table-sources" */).then(c => wrapFunctional(c.default || c))
export const TableSuppliers = () => import('../../components/buying/TableSuppliers.vue' /* webpackChunkName: "components/table-suppliers" */).then(c => wrapFunctional(c.default || c))
export const Activities = () => import('../../components/campaigns/Activities.vue' /* webpackChunkName: "components/activities" */).then(c => wrapFunctional(c.default || c))
export const CampaignDetailsV2 = () => import('../../components/campaigns/CampaignDetails-v2.vue' /* webpackChunkName: "components/campaign-details-v2" */).then(c => wrapFunctional(c.default || c))
export const CampaignDetails = () => import('../../components/campaigns/CampaignDetails.vue' /* webpackChunkName: "components/campaign-details" */).then(c => wrapFunctional(c.default || c))
export const CampaignStatus = () => import('../../components/campaigns/CampaignStatus.vue' /* webpackChunkName: "components/campaign-status" */).then(c => wrapFunctional(c.default || c))
export const Comments = () => import('../../components/campaigns/Comments.vue' /* webpackChunkName: "components/comments" */).then(c => wrapFunctional(c.default || c))
export const Material = () => import('../../components/campaigns/Material.vue' /* webpackChunkName: "components/material" */).then(c => wrapFunctional(c.default || c))
export const Materials = () => import('../../components/campaigns/Materials.vue' /* webpackChunkName: "components/materials" */).then(c => wrapFunctional(c.default || c))
export const TableCampaigns = () => import('../../components/campaigns/TableCampaigns.vue' /* webpackChunkName: "components/table-campaigns" */).then(c => wrapFunctional(c.default || c))
export const ToggleView = () => import('../../components/campaigns/ToggleView.vue' /* webpackChunkName: "components/toggle-view" */).then(c => wrapFunctional(c.default || c))
export const CircularProgress = () => import('../../components/charts/CircularProgress.vue' /* webpackChunkName: "components/circular-progress" */).then(c => wrapFunctional(c.default || c))
export const ActivitiesList = () => import('../../components/dashboard/ActivitiesList.vue' /* webpackChunkName: "components/activities-list" */).then(c => wrapFunctional(c.default || c))
export const BarChartCard = () => import('../../components/dashboard/BarChartCard.vue' /* webpackChunkName: "components/bar-chart-card" */).then(c => wrapFunctional(c.default || c))
export const Category = () => import('../../components/dashboard/Category.vue' /* webpackChunkName: "components/category" */).then(c => wrapFunctional(c.default || c))
export const LineChartCard = () => import('../../components/dashboard/LineChartCard.vue' /* webpackChunkName: "components/line-chart-card" */).then(c => wrapFunctional(c.default || c))
export const NewCampaign = () => import('../../components/dashboard/NewCampaign.vue' /* webpackChunkName: "components/new-campaign" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/dashboard/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const PieChartCard = () => import('../../components/dashboard/PieChartCard.vue' /* webpackChunkName: "components/pie-chart-card" */).then(c => wrapFunctional(c.default || c))
export const Suppliers = () => import('../../components/dashboard/Suppliers.vue' /* webpackChunkName: "components/suppliers" */).then(c => wrapFunctional(c.default || c))
export const WhiteSpotCard = () => import('../../components/dashboard/WhiteSpotCard.vue' /* webpackChunkName: "components/white-spot-card" */).then(c => wrapFunctional(c.default || c))
export const Articles = () => import('../../components/formulate/Articles.vue' /* webpackChunkName: "components/articles" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/formulate/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../../components/formulate/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const Date = () => import('../../components/formulate/Date.vue' /* webpackChunkName: "components/date" */).then(c => wrapFunctional(c.default || c))
export const MultiselectInput = () => import('../../components/formulate/MultiselectInput.vue' /* webpackChunkName: "components/multiselect-input" */).then(c => wrapFunctional(c.default || c))
export const SourceInput = () => import('../../components/formulate/SourceInput.vue' /* webpackChunkName: "components/source-input" */).then(c => wrapFunctional(c.default || c))
export const Toggle = () => import('../../components/formulate/Toggle.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c))
export const ArticleMetaTable = () => import('../../components/online-salespositions/ArticleMetaTable.vue' /* webpackChunkName: "components/article-meta-table" */).then(c => wrapFunctional(c.default || c))
export const SalesPositionDetails = () => import('../../components/online-salespositions/SalesPositionDetails.vue' /* webpackChunkName: "components/sales-position-details" */).then(c => wrapFunctional(c.default || c))
export const SalesPositionWizard = () => import('../../components/online-salespositions/SalesPositionWizard.vue' /* webpackChunkName: "components/sales-position-wizard" */).then(c => wrapFunctional(c.default || c))
export const TableSalesPositions = () => import('../../components/online-salespositions/TableSalesPositions.vue' /* webpackChunkName: "components/table-sales-positions" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../components/shortcomings/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const TableShortcomings = () => import('../../components/shortcomings/TableShortcomings.vue' /* webpackChunkName: "components/table-shortcomings" */).then(c => wrapFunctional(c.default || c))
export const Single = () => import('../../components/swipers/Single.vue' /* webpackChunkName: "components/single" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/Footer.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/Header.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const BmsMediaWizard = () => import('../../components/bms/MediaWizard.vue' /* webpackChunkName: "components/bms-media-wizard" */).then(c => wrapFunctional(c.default || c))
export const BmsPackageDetails = () => import('../../components/bms/PackageDetails.vue' /* webpackChunkName: "components/bms-package-details" */).then(c => wrapFunctional(c.default || c))
export const BmsPackageWizard = () => import('../../components/bms/PackageWizard.vue' /* webpackChunkName: "components/bms-package-wizard" */).then(c => wrapFunctional(c.default || c))
export const BmsPositionDetails = () => import('../../components/bms/PositionDetails.vue' /* webpackChunkName: "components/bms-position-details" */).then(c => wrapFunctional(c.default || c))
export const BmsPositionWizard = () => import('../../components/bms/PositionWizard.vue' /* webpackChunkName: "components/bms-position-wizard" */).then(c => wrapFunctional(c.default || c))
export const BmsScenarioCard = () => import('../../components/bms/ScenarioCard.vue' /* webpackChunkName: "components/bms-scenario-card" */).then(c => wrapFunctional(c.default || c))
export const BmsSupplierView = () => import('../../components/bms/SupplierView.vue' /* webpackChunkName: "components/bms-supplier-view" */).then(c => wrapFunctional(c.default || c))
export const BmsView = () => import('../../components/bms/View.vue' /* webpackChunkName: "components/bms-view" */).then(c => wrapFunctional(c.default || c))
export const AdvertisingMaterialsSubmitAdvertisingMaterials = () => import('../../components/advertising-materials/SubmitAdvertisingMaterials.vue' /* webpackChunkName: "components/advertising-materials-submit-advertising-materials" */).then(c => wrapFunctional(c.default || c))
export const BudgetDetails = () => import('../../components/budget/Details.vue' /* webpackChunkName: "components/budget-details" */).then(c => wrapFunctional(c.default || c))
export const BudgetForm = () => import('../../components/budget/Form.vue' /* webpackChunkName: "components/budget-form" */).then(c => wrapFunctional(c.default || c))
export const BudgetStatus = () => import('../../components/budget/Status.vue' /* webpackChunkName: "components/budget-status" */).then(c => wrapFunctional(c.default || c))
export const BudgetTable = () => import('../../components/budget/Table.vue' /* webpackChunkName: "components/budget-table" */).then(c => wrapFunctional(c.default || c))
export const CatalogExport = () => import('../../components/catalog/Export.vue' /* webpackChunkName: "components/catalog-export" */).then(c => wrapFunctional(c.default || c))
export const CatalogFilter = () => import('../../components/catalog/Filter.vue' /* webpackChunkName: "components/catalog-filter" */).then(c => wrapFunctional(c.default || c))
export const CatalogTable = () => import('../../components/catalog/Table.vue' /* webpackChunkName: "components/catalog-table" */).then(c => wrapFunctional(c.default || c))
export const Evaluation = () => import('../../components/evaluation/index.vue' /* webpackChunkName: "components/evaluation" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowBack = () => import('../../components/icons/arrow-back.vue' /* webpackChunkName: "components/icons-arrow-back" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckmarkWhite = () => import('../../components/icons/checkmark-white.vue' /* webpackChunkName: "components/icons-checkmark-white" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckmark = () => import('../../components/icons/checkmark.vue' /* webpackChunkName: "components/icons-checkmark" */).then(c => wrapFunctional(c.default || c))
export const IconsChevron = () => import('../../components/icons/chevron.vue' /* webpackChunkName: "components/icons-chevron" */).then(c => wrapFunctional(c.default || c))
export const IconsCrossWhite = () => import('../../components/icons/cross-white.vue' /* webpackChunkName: "components/icons-cross-white" */).then(c => wrapFunctional(c.default || c))
export const IconsDownload = () => import('../../components/icons/download.vue' /* webpackChunkName: "components/icons-download" */).then(c => wrapFunctional(c.default || c))
export const IconsHamburger = () => import('../../components/icons/hamburger.vue' /* webpackChunkName: "components/icons-hamburger" */).then(c => wrapFunctional(c.default || c))
export const IconsInfo = () => import('../../components/icons/info.vue' /* webpackChunkName: "components/icons-info" */).then(c => wrapFunctional(c.default || c))
export const IconsLogOut = () => import('../../components/icons/log-out.vue' /* webpackChunkName: "components/icons-log-out" */).then(c => wrapFunctional(c.default || c))
export const IconsXMark = () => import('../../components/icons/x-mark.vue' /* webpackChunkName: "components/icons-x-mark" */).then(c => wrapFunctional(c.default || c))
export const PlanCreateForm = () => import('../../components/plan/CreateForm.vue' /* webpackChunkName: "components/plan-create-form" */).then(c => wrapFunctional(c.default || c))
export const PlanDetails = () => import('../../components/plan/Details.vue' /* webpackChunkName: "components/plan-details" */).then(c => wrapFunctional(c.default || c))
export const PlanForm = () => import('../../components/plan/Form.vue' /* webpackChunkName: "components/plan-form" */).then(c => wrapFunctional(c.default || c))
export const PlanPresentation = () => import('../../components/plan/Presentation.vue' /* webpackChunkName: "components/plan-presentation" */).then(c => wrapFunctional(c.default || c))
export const PlanSlide = () => import('../../components/plan/Slide.vue' /* webpackChunkName: "components/plan-slide" */).then(c => wrapFunctional(c.default || c))
export const PlanStatus = () => import('../../components/plan/Status.vue' /* webpackChunkName: "components/plan-status" */).then(c => wrapFunctional(c.default || c))
export const PlanTable = () => import('../../components/plan/Table.vue' /* webpackChunkName: "components/plan-table" */).then(c => wrapFunctional(c.default || c))
export const InsightsActions = () => import('../../components/insights/Actions.vue' /* webpackChunkName: "components/insights-actions" */).then(c => wrapFunctional(c.default || c))
export const InsightsBar = () => import('../../components/insights/Bar.vue' /* webpackChunkName: "components/insights-bar" */).then(c => wrapFunctional(c.default || c))
export const InsightsBarHorizontal = () => import('../../components/insights/BarHorizontal.vue' /* webpackChunkName: "components/insights-bar-horizontal" */).then(c => wrapFunctional(c.default || c))
export const InsightsCard = () => import('../../components/insights/Card.vue' /* webpackChunkName: "components/insights-card" */).then(c => wrapFunctional(c.default || c))
export const InsightsWaterfall = () => import('../../components/insights/Waterfall.vue' /* webpackChunkName: "components/insights-waterfall" */).then(c => wrapFunctional(c.default || c))
export const SeriesTable = () => import('../../components/series/table.vue' /* webpackChunkName: "components/series-table" */).then(c => wrapFunctional(c.default || c))
export const SourceBudget = () => import('../../components/source/Budget.vue' /* webpackChunkName: "components/source-budget" */).then(c => wrapFunctional(c.default || c))
export const SourceChangeRequestModal = () => import('../../components/source/ChangeRequestModal.vue' /* webpackChunkName: "components/source-change-request-modal" */).then(c => wrapFunctional(c.default || c))
export const SourceChangeRequests = () => import('../../components/source/ChangeRequests.vue' /* webpackChunkName: "components/source-change-requests" */).then(c => wrapFunctional(c.default || c))
export const SourceImagePreview = () => import('../../components/source/ImagePreview.vue' /* webpackChunkName: "components/source-image-preview" */).then(c => wrapFunctional(c.default || c))
export const EvaluationCardImage = () => import('../../components/evaluation/Card/Image.vue' /* webpackChunkName: "components/evaluation-card-image" */).then(c => wrapFunctional(c.default || c))
export const EvaluationCardTable = () => import('../../components/evaluation/Card/Table.vue' /* webpackChunkName: "components/evaluation-card-table" */).then(c => wrapFunctional(c.default || c))
export const EvaluationCardText = () => import('../../components/evaluation/Card/Text.vue' /* webpackChunkName: "components/evaluation-card-text" */).then(c => wrapFunctional(c.default || c))
export const EvaluationCard = () => import('../../components/evaluation/Card/index.vue' /* webpackChunkName: "components/evaluation-card" */).then(c => wrapFunctional(c.default || c))
export const EvaluationCardPartsButtons = () => import('../../components/evaluation/Card/Parts/Buttons.vue' /* webpackChunkName: "components/evaluation-card-parts-buttons" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
