import { render, staticRenderFns } from "./default.vue?vue&type=template&id=78e7c4eb&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/app/Header.vue').default,Breadcrumbs: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/base/Breadcrumbs.vue').default,AppFooter: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/app/Footer.vue').default})
