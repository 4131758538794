import Vue from 'vue';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faUsers,
  faChevronRight,
  faChevronLeft,
  faX,
  faNewspaper,
  faClipboard,
  faPencil,
  faChartPie,
  faChartBar,
  faChartLine,
  faCog,
  faClipboardCheck,
  faClipboardQuestion,
  faChartGantt,
  faEuro,
  faBoxOpen,
  faClock,
  faPhone,
  faLaptop,
  faCow,
  faPercentage,
  faBarcode,
  faComment,
  faLink,
  faEye,
  faChevronUp,
  faChevronDown,
  faSort,
  faWarning,
  faExternalLink,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faChevronUp,
  faChevronDown,
  faUsers,
  faChevronRight,
  faChevronLeft,
  faX,
  faNewspaper,
  faClipboard,
  faPencil,
  faChartPie,
  faChartBar,
  faChartLine,
  faCog,
  faClipboardCheck,
  faClipboardQuestion,
  faChartGantt,
  faEuro,
  faBoxOpen,
  faClock,
  faPhone,
  faLaptop,
  faCow,
  faPercentage,
  faBarcode,
  faComment,
  faLink,
  faEye,
  faSort,
  faWarning,
  faExternalLink,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
