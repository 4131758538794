
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    staticWidth: {
      type: Boolean,
      default: true,
    },
    heading: {
      type: String,
      required: true,
    },
    link: {
      type: Object,
      default: null,
    },
    footerButtonText: {
      type: String,
      default: 'Bevestigen',
    },
  },
  methods: {
    close() {
      this.$emit('update:open', false);
    },
  },
};
