
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      },
    },
  },
};
