// Better version of the routeOption method in @nuxtjs/auth that also includes checking for array content
const routeOption = (route, key, value, checkIncludes = false, returnFound = false) => {
  let found = null;

  // Loop over all the matched pages (will most likely always be only one match)
  route.matched.forEach((m) => {
    // Loop over all the components in the page
    return Object.values(m.components).forEach((component) => {
      if (process.server) {
        // Loop over the circular component structure to get the component options
        return Object.values(component._Ctor).forEach((ctor) => {
          // If the component has options (not always true), set the found value to the desired options value.
          if (ctor.options) {
            found = ctor.options[key];
          }
        });
      }
      // The same as what's happening in the above if statement
      if (component.options) {
        found = component.options[key];
      }
    });
  });

  if (found && returnFound) {
    return found;
  }

  // Check if we need to check if the array includes our desired value and check if found is an array.
  if (checkIncludes && Array.isArray(found)) {
    return found && found.includes(value);
  }

  return found === value;
};

export default ({redirect, error, route, $auth}) => {
  // Check if we should even check the roles for this route.
  // This will end the middleware if the route has the `auth` option set to false
  // or the middleware set to guest.
  if (
    routeOption(route, 'auth', false) ||
    routeOption(route, 'middleware', 'guest')
  ) {
    return;
  }

  // Check if the user has the right role for this route based on the page options and user role.
  const userScopes = $auth.user ? $auth.user.scope : null;
  const foundScope = routeOption(route, 'scope', userScopes, false, true);
  if (foundScope && !$auth.hasScope(foundScope)) {
    error({statusCode: 403, message: 'Forbidden'});
  }
};
