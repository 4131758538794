import { render, staticRenderFns } from "./wide.vue?vue&type=template&id=19089e84&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/app/Header.vue').default,AppFooter: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/app/Footer.vue').default})
