export const state = () => ({
  title: null,
  customCrumbs: [],
});

export const mutations = {
  SET_TITLE(state, title) {
    state.title = title;
  },
  SET_CUSTOM_CRUMBS(state, crumbs) {
    state.customCrumbs = crumbs;
  },
};
