
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    calendarOpen: false,
  }),
  computed: {
    value: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      },
    },
    attributes() {
      return this.context.attributes.attributes;
    },
    sourcesYears() {
      const years = {};
      if (!this.attributes?.sources) {
        return [];
      }
      this.attributes.sources.forEach((source) => {
        const year = new Date(source.duration_start).getFullYear();
        if (!years[year]) {
          years[year] = [];
        }
        years[year].push(source);
      });
      return years;
    },
    categorizedSources() {
      const categories = {};
      Object.values(this.sourcesYears).forEach((sources) => {
        const year = new Date(sources[0].duration_start).getFullYear();
        categories[year] = {};
        sources.forEach((source) => {
          const sourceSplit = source.title.split(' - ');
          source.filteredTitle = sourceSplit[1] + ' ' + sourceSplit[2];

          const category = source.type + ' ' + source.title.replace(/[0-9#]/g, '');
          if (!categories[year][category]) {
            categories[year][category] = [];
          }

          categories[year][category].push(source);
        });
      });
      return categories;
    },
  },
  methods: {
    subtractFourWeeks(date) {
      return this.$moment(new Date(date)).subtract(4, 'weeks').format('YYYY-MM-DD');
    },
    setSource(source) {
      this.value = (source.id).toString();
      this.calendarOpen = false;
    },
  },
};
