import { render, staticRenderFns } from "./ArticlePicker.vue?vue&type=template&id=2cdb4c96&"
import script from "./ArticlePicker.vue?vue&type=script&lang=js&"
export * from "./ArticlePicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/formulate/Button.vue').default,Modal: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/base/Modal.vue').default})
