import Vue from 'vue';

const CustomCrumbs = {
  install(Vue) {
    Vue.mixin({
      beforeRouteLeave(to, from, next) {
        this.$store.commit('breadcrumbs/SET_CUSTOM_CRUMBS', []);
        next();
      },
    });
  },
};

Vue.use(CustomCrumbs);
