
import {mapGetters} from 'vuex';
import menu from '~/graphql/queries/menu';
import lastMagazine from '~/graphql/queries/lastMagazine';
import lastPost from '~/graphql/queries/lastPost';

export default {
  apollo: {
    baseMenu: {
      query: menu,
      variables: {
        name: 'Connect',
      },
      update(data) {
        return data.menu;
      },
    },
    roleMenu: {
      query: menu,
      variables() {
        return {
          name: this.roleMenuName,
        };
      },
      skip() {
        return !this.roleMenuName;
      },
      update(data) {
        return data.menu;
      },
    },
    lastMagazine: {
      query: lastMagazine,
    },
    lastPost: {
      query: lastPost,
    },
  },
  data: () => ({
    baseMenu: {items: []},
    roleMenu: {items: []},
    activeSubmenuIndex: null,
  }),
  computed: {
    ...mapGetters({
      isBuyer: 'role/isBuyer',
      isConnect: 'role/isConnect',
      isSupplier: 'role/isSupplier',
      isTraffic: 'role/isTraffic',
      isEcommerce: 'role/isEcommerce',
    }),
    roleMenuName() {
      switch (true) {
        case this.isSupplier:
          return 'Leverancier';

        case this.isEcommerce || this.isConnect:
          return 'Ecommerce';

        case this.isBuyer:
          return 'Inkoper';

        case this.isTraffic:
          return 'Traffic';

        default:
          return '';
      }
    },
    menuItems() {
      return [
        ...this.baseMenu.items,
        ...this.roleMenu.items,
      ];
    },
  },
  methods: {
    submenuToggle(index) {
      this.activeSubmenuIndex = index;
    },
    closeSubmenu() {
      this.activeSubmenuIndex = null;
    },
  },
};
