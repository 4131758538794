import Vue from 'vue';
import {getOperationName} from 'apollo-utilities';

const apolloCacheHelper = {
  methods: {
    updateCacheItem(query, variables, callback) {
      const cache = this.$apollo.provider.defaultClient.cache;
      const cacheData = cache.readQuery({
        query,
        variables,
      });

      const operationName = getOperationName(query);
      const updatedData = callback(cacheData[operationName]);

      cache.writeQuery({
        query,
        variables,
        data: {
          [operationName]: updatedData,
        },
      });
    },
  },
};

Vue.mixin(apolloCacheHelper);
