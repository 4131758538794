
export default {
  props: {
    noSpace: {
      type: Boolean,
      default: false,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
  },
};
