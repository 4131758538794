
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  methods: {
    checkAttribute(attribute) {
      const foundAttribute = this.context.attributes[attribute];
      return foundAttribute && (foundAttribute === '' || foundAttribute === true);
    },
  },
};
