
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    values: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      },
    },
    attributes() {
      return Object.assign(this.context.attributes, {id: null, class: null});
    },
  },
};
