import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import {nl} from '@braid/vue-formulate-i18n';
import errorHandler from '~/libs/form-error-handler';

import Date from '~/components/formulate/Date';
import Toggle from '~/components/formulate/Toggle';
import Button from '~/components/formulate/Button';
import Datepicker from '~/components/formulate/Calendar';
import Articles from '~/components/formulate/Articles';
import MultiselectInput from '~/components/formulate/MultiselectInput';
import SourceInput from '~/components/formulate/SourceInput';

export const formulateOptions = {
  library: {
    button: {
      classification: 'button',
      component: Button,
    },
    submit: {
      classification: 'button',
      component: Button,
    },
    toggle: {
      classification: 'checkbox',
      component: Toggle,
    },
    date: {
      classification: 'date',
      component: Date,
    },
    calendar: {
      classification: 'calendar',
      component: Datepicker,
      slotProps: {
        component: ['now'],
      },
    },
    articles: {
      classification: 'text',
      component: Articles,
    },
    multiselect: {
      classification: 'multiselect',
      component: MultiselectInput,
    },
    source: {
      classification: 'select',
      component: SourceInput,
    },
  },
  plugins: [nl],
  classes: {
    input(context) {
      switch (context.classification) {
        case 'button':
          return 'btn btn-primary';
        case 'box':
          return '';
        case 'group':
          return '';
        case 'file':
          return 'appearance-none opacity-0 cursor-pointer absolute inset-0 w-full h-full z-10';
        default:
          return 'block w-full text-gray-darker border border-gray focus:shadow-forms outline-none p-2.5 rounded';
      }
    },
    element({classification}) {
      switch (classification) {
        case 'box':
          return 'flex items-center px-1';
        default:
          return 'w-full relative';
      }
    },
    outer(context) {
      if (context.isGrouped) {
        return '';
      } else {
        switch (context.classification) {
          case 'button':
            return '';
          case 'radio':
            return '';
          default:
            return 'mb-4';
        }
      }
    },
    wrapper(context) {
      switch (context.classification) {
        case 'button':
          return '';
        case 'box':
          return 'w-full flex justify-start';
        default:
          return '';
      }
    },
    label(context) {
      switch (context.classification) {
        case 'box':
          return 'font-body flex-grow ml-2 text-left checkbox-label';
        default:
          return 'text-gray-darker font-semibold font-normal font-sans';
      }
    },
    /* decorator: ({hasValue}) => {
      let base = 'border rounded border-gray focus:border-primary-light inline-block w-4 h-4 mr-2';
      if (hasValue) {
        base += ' bg-blue-light';
      }
      return base;
    }, */
    help: 'text-xs mb-1 text-gray-dark',
    error: 'text-error text-xs mb-1',
    uploadArea: 'upload-area',
    file: ' appearance-none rounded border p-3 block flex relative items-center justify-center',
    files: 'hidden',
    uploadAreaMask({attrs: {loading}}) {
      const base = 'absolute pointer-events-none flex justify-center items-center inset-0 border-2 border-dashed border-gray rounded upload-placeholder';

      if (loading) {
        return `${base} loading`;
      } else {
        return base;
      }
    },
  },
  errorHandler,
};

Vue.component('Date', Date);
Vue.component('Toggle', Toggle);
Vue.component('Datepicker', Datepicker);
Vue.component('Button', Button);
Vue.component('ArticlePickerInput', Articles);

Vue.use(VueFormulate, formulateOptions);

export {
  VueFormulate,
  Articles,
  Button,
  Date,
  Datepicker,
  Toggle,
};
