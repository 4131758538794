import {ROLE_LABELS} from '~/plugins/constants';

export default ({store, $gtm}) => {
  if (store.state.auth.loggedIn) {
    $gtm.push({
      user_id: store.state.auth.user.uuid,
      user_role: ROLE_LABELS[store.state.auth.user.role_id],
      interal_user: store.state.auth.user.is_internal ? 'true' : 'false',
    });
  }
};
