import {
  ROLE_SUPPLIER,
} from '~/plugins/constants';

export default ({store, route, redirect}) => {
  if (store.state.auth.loggedIn) {
    if (store.state.auth.user.role_id !== ROLE_SUPPLIER) {
      const path = route.path;
      // Get all available routes and map to their paths
      const allRoutes = store.$router.getRoutes().map(a => a.path);

      if (path.startsWith('/reach-campaigns') && !isExcluded(path)) {
        // Check if a version of the path with buying instead of reach-campaigns exists. If so, redirect to it
        if (allRoutes.includes(route.matched[0].path)) {
          redirect(path.replace('reach-campaigns', 'buying'));
        } else {
          redirect('/');
        }
      }
    }
  }

  function isExcluded(string) {
    // List of pages which should not be redirected to '/buying'
    const exclusions = ['create', 'evaluation', 'planning', 'budget', 'cases', 'plans', 'merkzichtbaarheid'];
    let isExcluded = false;

    exclusions.forEach((exception) => {
      if (string.includes(exception)) {
        isExcluded = true;
      }
    });

    return isExcluded;
  }
};
