
import {mapState} from 'vuex';
import breadcrumbsQuery from '~/graphql/queries/breadcrumbs.gql';

const titleCase = require('ap-style-title-case');

export default {
  apollo: {
    breadcrumbs: {
      query: breadcrumbsQuery,
    },
  },
  data: () => ({
    breadcrumbs: [],
    manualMapping: {
      create: 'Toevoegen',
      edit: 'Bewerken',
      news: 'Nieuws',
      plans: 'Jaarplannen',
      campaigns: 'Mediapakketten',
      'promotional-positions': 'Mediaposities',
      'bms-positions': 'Mediaposities',
      'bms-packages': 'Mediapakketten',
      buying: 'Jouw relaties',
      suppliers: 'Leveranciers',
      contact: 'Contact',
      shortcoming: 'Manco melden',
      insights: 'Inzichten',
    },
  }),
  computed: {
    ...mapState('breadcrumbs', [
      'title',
      'customCrumbs',
    ]),
    params() {
      const fullPath = this.$route.path;
      return fullPath.startsWith('/')
        ? fullPath.substring(1).split('/')
        : fullPath.split('/');
    },
    crumbs() {
      const params = this.params;
      const crumbs = [];
      let path = '';

      params.forEach((param, index) => {
        if (/[\w]{8}(-[\w]{4}){3}-[\w]{12}/.test(param)) {
          // Skip uuids
          return;
        }

        path = `${path}/${param}`;
        const match = this.$router.match(path);

        if (match.name !== null) {
          crumbs.push({
            title: this.prepareCrumb(path, param),
            ...match,
          });
        }
      });

      return crumbs;
    },
    hasUuid() {
      let hasUuid = false;

      this.params.forEach((param) => {
        if (/[\w]{8}(-[\w]{4}){3}-[\w]{12}/.test(param)) {
          // Skip uuids
          hasUuid = true;
        }
      });

      return hasUuid;
    },
    mapping() {
      const mapping = [];

      this.breadcrumbs.forEach(item => mapping[item.slug] = item.title);

      return mapping;
    },
  },
  methods: {
    prepareCrumb(path, param) {
      return this.manualMapping[param] || this.mapping[this.trim(path, '/')] || this.mapping[param] || titleCase(param.replace(/-/g, ' '));
    },
    trim(str, ch) {
      let start = 0;
      let end = str.length;

      while (start < end && str[start] === ch) { ++start; }

      while (end > start && str[end - 1] === ch) { --end; }

      return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    },
  },
};
