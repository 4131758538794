import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b0e62854 = () => interopDefault(import('../pages/buying/index.vue' /* webpackChunkName: "pages/buying/index" */))
const _37844bb0 = () => interopDefault(import('../pages/info/index.vue' /* webpackChunkName: "pages/info/index" */))
const _29395195 = () => interopDefault(import('../pages/insights/index.vue' /* webpackChunkName: "pages/insights/index" */))
const _3b79a73a = () => interopDefault(import('../pages/introductie.vue' /* webpackChunkName: "pages/introductie" */))
const _7f80b41a = () => interopDefault(import('../pages/leveranciersdag.vue' /* webpackChunkName: "pages/leveranciersdag" */))
const _5d070099 = () => interopDefault(import('../pages/leveranciersdag2023.vue' /* webpackChunkName: "pages/leveranciersdag2023" */))
const _688ee23e = () => interopDefault(import('../pages/perfect-online-store/index.vue' /* webpackChunkName: "pages/perfect-online-store/index" */))
const _42a72563 = () => interopDefault(import('../pages/presentatie.vue' /* webpackChunkName: "pages/presentatie" */))
const _4f8d577c = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _d7f64fb6 = () => interopDefault(import('../pages/reach-campaigns/index.vue' /* webpackChunkName: "pages/reach-campaigns/index" */))
const _1e448ae8 = () => interopDefault(import('../pages/roundtables.vue' /* webpackChunkName: "pages/roundtables" */))
const _21be6936 = () => interopDefault(import('../pages/xtra/index.vue' /* webpackChunkName: "pages/xtra/index" */))
const _7e0285b9 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _20b9db7e = () => interopDefault(import('../pages/buying/bms-packages/index.vue' /* webpackChunkName: "pages/buying/bms-packages/index" */))
const _9be128a8 = () => interopDefault(import('../pages/buying/bms-positions/index.vue' /* webpackChunkName: "pages/buying/bms-positions/index" */))
const _059e54d5 = () => interopDefault(import('../pages/buying/budgets/index.vue' /* webpackChunkName: "pages/buying/budgets/index" */))
const _634fdb2a = () => interopDefault(import('../pages/buying/campaigns/index.vue' /* webpackChunkName: "pages/buying/campaigns/index" */))
const _8ea2a19c = () => interopDefault(import('../pages/buying/promotional-positions/index.vue' /* webpackChunkName: "pages/buying/promotional-positions/index" */))
const _284f81e6 = () => interopDefault(import('../pages/buying/series/index.vue' /* webpackChunkName: "pages/buying/series/index" */))
const _acf15624 = () => interopDefault(import('../pages/buying/suppliers/index.vue' /* webpackChunkName: "pages/buying/suppliers/index" */))
const _028ca2c5 = () => interopDefault(import('../pages/info/audiences.vue' /* webpackChunkName: "pages/info/audiences" */))
const _84fef214 = () => interopDefault(import('../pages/info/contact.vue' /* webpackChunkName: "pages/info/contact" */))
const _71563410 = () => interopDefault(import('../pages/info/magazines/index.vue' /* webpackChunkName: "pages/info/magazines/index" */))
const _0339b530 = () => interopDefault(import('../pages/info/news/index.vue' /* webpackChunkName: "pages/info/news/index" */))
const _95df020a = () => interopDefault(import('../pages/info/resources.vue' /* webpackChunkName: "pages/info/resources" */))
const _3923d02a = () => interopDefault(import('../pages/insights/general.vue' /* webpackChunkName: "pages/insights/general" */))
const _35950731 = () => interopDefault(import('../pages/insights/lost.vue' /* webpackChunkName: "pages/insights/lost" */))
const _db9ee6c0 = () => interopDefault(import('../pages/insights/recurring.vue' /* webpackChunkName: "pages/insights/recurring" */))
const _57120116 = () => interopDefault(import('../pages/insights/whitespots.vue' /* webpackChunkName: "pages/insights/whitespots" */))
const _72a888e8 = () => interopDefault(import('../pages/perfect-online-store/catalog.vue' /* webpackChunkName: "pages/perfect-online-store/catalog" */))
const _31e1f9cc = () => interopDefault(import('../pages/perfect-online-store/fix-the-basics.vue' /* webpackChunkName: "pages/perfect-online-store/fix-the-basics" */))
const _37f9270a = () => interopDefault(import('../pages/perfect-online-store/series/index.vue' /* webpackChunkName: "pages/perfect-online-store/series/index" */))
const _a29e79e2 = () => interopDefault(import('../pages/perfect-online-store/shortcoming/index.vue' /* webpackChunkName: "pages/perfect-online-store/shortcoming/index" */))
const _65dbde52 = () => interopDefault(import('../pages/reach-campaigns/bms-packages/index.vue' /* webpackChunkName: "pages/reach-campaigns/bms-packages/index" */))
const _02f021bb = () => interopDefault(import('../pages/reach-campaigns/bms-positions/index.vue' /* webpackChunkName: "pages/reach-campaigns/bms-positions/index" */))
const _cce58dfc = () => interopDefault(import('../pages/reach-campaigns/budget.vue' /* webpackChunkName: "pages/reach-campaigns/budget" */))
const _2e63f0b9 = () => interopDefault(import('../pages/reach-campaigns/campaigns/index.vue' /* webpackChunkName: "pages/reach-campaigns/campaigns/index" */))
const _0c37cfce = () => interopDefault(import('../pages/reach-campaigns/cases/index.vue' /* webpackChunkName: "pages/reach-campaigns/cases/index" */))
const _cdc9604c = () => interopDefault(import('../pages/reach-campaigns/planning.vue' /* webpackChunkName: "pages/reach-campaigns/planning" */))
const _ff08337e = () => interopDefault(import('../pages/reach-campaigns/promotional-positions/index.vue' /* webpackChunkName: "pages/reach-campaigns/promotional-positions/index" */))
const _80a8d204 = () => interopDefault(import('../pages/auth/password/reset/index.vue' /* webpackChunkName: "pages/auth/password/reset/index" */))
const _77c324ba = () => interopDefault(import('../pages/perfect-online-store/series/create.vue' /* webpackChunkName: "pages/perfect-online-store/series/create" */))
const _4f1be98f = () => interopDefault(import('../pages/perfect-online-store/shortcoming/create.vue' /* webpackChunkName: "pages/perfect-online-store/shortcoming/create" */))
const _7d5436ac = () => interopDefault(import('../pages/reach-campaigns/bms-packages/create.vue' /* webpackChunkName: "pages/reach-campaigns/bms-packages/create" */))
const _e97f9144 = () => interopDefault(import('../pages/reach-campaigns/bms-packages/info.vue' /* webpackChunkName: "pages/reach-campaigns/bms-packages/info" */))
const _fa6f3f3a = () => interopDefault(import('../pages/reach-campaigns/bms-positions/create.vue' /* webpackChunkName: "pages/reach-campaigns/bms-positions/create" */))
const _74631db6 = () => interopDefault(import('../pages/reach-campaigns/campaigns/create.vue' /* webpackChunkName: "pages/reach-campaigns/campaigns/create" */))
const _586e87d2 = () => interopDefault(import('../pages/reach-campaigns/campaigns/info.vue' /* webpackChunkName: "pages/reach-campaigns/campaigns/info" */))
const _9295a6c6 = () => interopDefault(import('../pages/reach-campaigns/promotional-positions/create.vue' /* webpackChunkName: "pages/reach-campaigns/promotional-positions/create" */))
const _66e539aa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _88e23f04 = () => interopDefault(import('../pages/auth/password/reset/_token.vue' /* webpackChunkName: "pages/auth/password/reset/_token" */))
const _056a44e0 = () => interopDefault(import('../pages/auth/invite/_token.vue' /* webpackChunkName: "pages/auth/invite/_token" */))
const _19e98dcc = () => interopDefault(import('../pages/buying/bms-packages/_uuid/index.vue' /* webpackChunkName: "pages/buying/bms-packages/_uuid/index" */))
const _fbac2a12 = () => interopDefault(import('../pages/buying/bms-positions/_uuid/index.vue' /* webpackChunkName: "pages/buying/bms-positions/_uuid/index" */))
const _206c43f5 = () => interopDefault(import('../pages/buying/campaigns/_uuid/index.vue' /* webpackChunkName: "pages/buying/campaigns/_uuid/index" */))
const _70e41c06 = () => interopDefault(import('../pages/buying/promotional-positions/_uuid/index.vue' /* webpackChunkName: "pages/buying/promotional-positions/_uuid/index" */))
const _84623ca4 = () => interopDefault(import('../pages/buying/series/_uuid.vue' /* webpackChunkName: "pages/buying/series/_uuid" */))
const _f3588eb6 = () => interopDefault(import('../pages/buying/suppliers/_supplierUuid/index.vue' /* webpackChunkName: "pages/buying/suppliers/_supplierUuid/index" */))
const _74c376a0 = () => interopDefault(import('../pages/info/magazines/_slug.vue' /* webpackChunkName: "pages/info/magazines/_slug" */))
const _018313e8 = () => interopDefault(import('../pages/info/news/_slug.vue' /* webpackChunkName: "pages/info/news/_slug" */))
const _7982cc43 = () => interopDefault(import('../pages/perfect-online-store/series/_uuid.vue' /* webpackChunkName: "pages/perfect-online-store/series/_uuid" */))
const _7536ec1a = () => interopDefault(import('../pages/perfect-online-store/shortcoming/_uuid/index.vue' /* webpackChunkName: "pages/perfect-online-store/shortcoming/_uuid/index" */))
const _00a3491d = () => interopDefault(import('../pages/reach-campaigns/bms-packages/_uuid/index.vue' /* webpackChunkName: "pages/reach-campaigns/bms-packages/_uuid/index" */))
const _72a799c6 = () => interopDefault(import('../pages/reach-campaigns/bms-positions/_uuid/index.vue' /* webpackChunkName: "pages/reach-campaigns/bms-positions/_uuid/index" */))
const _0ff03c28 = () => interopDefault(import('../pages/reach-campaigns/budgets/_uuid.vue' /* webpackChunkName: "pages/reach-campaigns/budgets/_uuid" */))
const _88109b78 = () => interopDefault(import('../pages/reach-campaigns/campaigns/_uuid/index.vue' /* webpackChunkName: "pages/reach-campaigns/campaigns/_uuid/index" */))
const _0fa5125e = () => interopDefault(import('../pages/reach-campaigns/cases/_slug.vue' /* webpackChunkName: "pages/reach-campaigns/cases/_slug" */))
const _5417e248 = () => interopDefault(import('../pages/reach-campaigns/plans/_uuid.vue' /* webpackChunkName: "pages/reach-campaigns/plans/_uuid" */))
const _51d361cc = () => interopDefault(import('../pages/reach-campaigns/promotional-positions/_uuid/index.vue' /* webpackChunkName: "pages/reach-campaigns/promotional-positions/_uuid/index" */))
const _17f52ae1 = () => interopDefault(import('../pages/reach-campaigns/scenarios/_id.vue' /* webpackChunkName: "pages/reach-campaigns/scenarios/_id" */))
const _3043ac00 = () => interopDefault(import('../pages/buying/bms-packages/_uuid/edit.vue' /* webpackChunkName: "pages/buying/bms-packages/_uuid/edit" */))
const _b1850516 = () => interopDefault(import('../pages/buying/bms-positions/_uuid/edit.vue' /* webpackChunkName: "pages/buying/bms-positions/_uuid/edit" */))
const _0225e692 = () => interopDefault(import('../pages/buying/campaigns/_uuid/edit.vue' /* webpackChunkName: "pages/buying/campaigns/_uuid/edit" */))
const _25597daf = () => interopDefault(import('../pages/buying/promotional-positions/_uuid/edit.vue' /* webpackChunkName: "pages/buying/promotional-positions/_uuid/edit" */))
const _19bf5ca8 = () => interopDefault(import('../pages/buying/suppliers/_supplierUuid/catalog.vue' /* webpackChunkName: "pages/buying/suppliers/_supplierUuid/catalog" */))
const _f46b681c = () => interopDefault(import('../pages/perfect-online-store/shortcoming/_uuid/edit.vue' /* webpackChunkName: "pages/perfect-online-store/shortcoming/_uuid/edit" */))
const _16acc28f = () => interopDefault(import('../pages/reach-campaigns/bms-packages/_uuid/edit.vue' /* webpackChunkName: "pages/reach-campaigns/bms-packages/_uuid/edit" */))
const _11b9cd01 = () => interopDefault(import('../pages/reach-campaigns/bms-packages/_uuid/evaluation.vue' /* webpackChunkName: "pages/reach-campaigns/bms-packages/_uuid/evaluation" */))
const _2e806d58 = () => interopDefault(import('../pages/reach-campaigns/bms-packages/_uuid/finish.vue' /* webpackChunkName: "pages/reach-campaigns/bms-packages/_uuid/finish" */))
const _e4118e74 = () => interopDefault(import('../pages/reach-campaigns/bms-positions/_uuid/edit.vue' /* webpackChunkName: "pages/reach-campaigns/bms-positions/_uuid/edit" */))
const _6bb9cef0 = () => interopDefault(import('../pages/reach-campaigns/campaigns/_uuid/edit.vue' /* webpackChunkName: "pages/reach-campaigns/campaigns/_uuid/edit" */))
const _8b67f78c = () => interopDefault(import('../pages/reach-campaigns/campaigns/_uuid/evaluation.vue' /* webpackChunkName: "pages/reach-campaigns/campaigns/_uuid/evaluation" */))
const _448dadde = () => interopDefault(import('../pages/reach-campaigns/campaigns/_uuid/finish.vue' /* webpackChunkName: "pages/reach-campaigns/campaigns/_uuid/finish" */))
const _29cf5800 = () => interopDefault(import('../pages/reach-campaigns/promotional-positions/_uuid/edit.vue' /* webpackChunkName: "pages/reach-campaigns/promotional-positions/_uuid/edit" */))
const _4361bc9a = () => interopDefault(import('../pages/buying/suppliers/_supplierUuid/budgets/create.vue' /* webpackChunkName: "pages/buying/suppliers/_supplierUuid/budgets/create" */))
const _a410b084 = () => interopDefault(import('../pages/buying/suppliers/_supplierUuid/plans/create.vue' /* webpackChunkName: "pages/buying/suppliers/_supplierUuid/plans/create" */))
const _a69bd2de = () => interopDefault(import('../pages/buying/suppliers/_supplierUuid/plans/_planUuid.vue' /* webpackChunkName: "pages/buying/suppliers/_supplierUuid/plans/_planUuid" */))
const _6c80f8f8 = () => interopDefault(import('../pages/buying/suppliers/_supplierUuid/budgets/_budgetUuid/edit.vue' /* webpackChunkName: "pages/buying/suppliers/_supplierUuid/budgets/_budgetUuid/edit" */))
const _b4536ae4 = () => interopDefault(import('../pages/buying/_slug.vue' /* webpackChunkName: "pages/buying/_slug" */))
const _2bd339fd = () => interopDefault(import('../pages/campaign-evaluation/_uuid.vue' /* webpackChunkName: "pages/campaign-evaluation/_uuid" */))
const _3af18e40 = () => interopDefault(import('../pages/info/_slug.vue' /* webpackChunkName: "pages/info/_slug" */))
const _2782b04d = () => interopDefault(import('../pages/insights/_slug.vue' /* webpackChunkName: "pages/insights/_slug" */))
const _6bfc24ce = () => interopDefault(import('../pages/perfect-online-store/_slug.vue' /* webpackChunkName: "pages/perfect-online-store/_slug" */))
const _db639246 = () => interopDefault(import('../pages/reach-campaigns/_slug.vue' /* webpackChunkName: "pages/reach-campaigns/_slug" */))
const _6a527c3a = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/buying",
    component: _b0e62854,
    pathToRegexpOptions: {"strict":true},
    name: "buying___nl"
  }, {
    path: "/info",
    component: _37844bb0,
    pathToRegexpOptions: {"strict":true},
    name: "info___nl"
  }, {
    path: "/insights",
    component: _29395195,
    pathToRegexpOptions: {"strict":true},
    name: "insights___nl"
  }, {
    path: "/introductie",
    component: _3b79a73a,
    pathToRegexpOptions: {"strict":true},
    name: "introductie___nl"
  }, {
    path: "/leveranciersdag",
    component: _7f80b41a,
    pathToRegexpOptions: {"strict":true},
    name: "leveranciersdag___nl"
  }, {
    path: "/leveranciersdag2023",
    component: _5d070099,
    pathToRegexpOptions: {"strict":true},
    name: "leveranciersdag2023___nl"
  }, {
    path: "/perfect-online-store",
    component: _688ee23e,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store___nl"
  }, {
    path: "/presentatie",
    component: _42a72563,
    pathToRegexpOptions: {"strict":true},
    name: "presentatie___nl"
  }, {
    path: "/profile",
    component: _4f8d577c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___nl"
  }, {
    path: "/reach-campaigns",
    component: _d7f64fb6,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns___nl"
  }, {
    path: "/roundtables",
    component: _1e448ae8,
    pathToRegexpOptions: {"strict":true},
    name: "roundtables___nl"
  }, {
    path: "/xtra",
    component: _21be6936,
    pathToRegexpOptions: {"strict":true},
    name: "xtra___nl"
  }, {
    path: "/auth/login",
    component: _7e0285b9,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___nl"
  }, {
    path: "/buying/bms-packages",
    component: _20b9db7e,
    pathToRegexpOptions: {"strict":true},
    name: "buying-bms-packages___nl"
  }, {
    path: "/buying/bms-positions",
    component: _9be128a8,
    pathToRegexpOptions: {"strict":true},
    name: "buying-bms-positions___nl"
  }, {
    path: "/buying/budgets",
    component: _059e54d5,
    pathToRegexpOptions: {"strict":true},
    name: "buying-budgets___nl"
  }, {
    path: "/buying/campaigns",
    component: _634fdb2a,
    pathToRegexpOptions: {"strict":true},
    name: "buying-campaigns___nl"
  }, {
    path: "/buying/promotional-positions",
    component: _8ea2a19c,
    pathToRegexpOptions: {"strict":true},
    name: "buying-promotional-positions___nl"
  }, {
    path: "/buying/series",
    component: _284f81e6,
    pathToRegexpOptions: {"strict":true},
    name: "buying-series___nl"
  }, {
    path: "/buying/suppliers",
    component: _acf15624,
    pathToRegexpOptions: {"strict":true},
    name: "buying-suppliers___nl"
  }, {
    path: "/info/audiences",
    component: _028ca2c5,
    pathToRegexpOptions: {"strict":true},
    name: "info-audiences___nl"
  }, {
    path: "/info/contact",
    component: _84fef214,
    pathToRegexpOptions: {"strict":true},
    name: "info-contact___nl"
  }, {
    path: "/info/magazines",
    component: _71563410,
    pathToRegexpOptions: {"strict":true},
    name: "info-magazines___nl"
  }, {
    path: "/info/news",
    component: _0339b530,
    pathToRegexpOptions: {"strict":true},
    name: "info-news___nl"
  }, {
    path: "/info/resources",
    component: _95df020a,
    pathToRegexpOptions: {"strict":true},
    name: "info-resources___nl"
  }, {
    path: "/insights/general",
    component: _3923d02a,
    pathToRegexpOptions: {"strict":true},
    name: "insights-general___nl"
  }, {
    path: "/insights/lost",
    component: _35950731,
    pathToRegexpOptions: {"strict":true},
    name: "insights-lost___nl"
  }, {
    path: "/insights/recurring",
    component: _db9ee6c0,
    pathToRegexpOptions: {"strict":true},
    name: "insights-recurring___nl"
  }, {
    path: "/insights/whitespots",
    component: _57120116,
    pathToRegexpOptions: {"strict":true},
    name: "insights-whitespots___nl"
  }, {
    path: "/perfect-online-store/catalog",
    component: _72a888e8,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-catalog___nl"
  }, {
    path: "/perfect-online-store/fix-the-basics",
    component: _31e1f9cc,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-fix-the-basics___nl"
  }, {
    path: "/perfect-online-store/series",
    component: _37f9270a,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-series___nl"
  }, {
    path: "/perfect-online-store/shortcoming",
    component: _a29e79e2,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-shortcoming___nl"
  }, {
    path: "/reach-campaigns/bms-packages",
    component: _65dbde52,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-packages___nl"
  }, {
    path: "/reach-campaigns/bms-positions",
    component: _02f021bb,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-positions___nl"
  }, {
    path: "/reach-campaigns/budget",
    component: _cce58dfc,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-budget___nl"
  }, {
    path: "/reach-campaigns/campaigns",
    component: _2e63f0b9,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-campaigns___nl"
  }, {
    path: "/reach-campaigns/cases",
    component: _0c37cfce,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-cases___nl"
  }, {
    path: "/reach-campaigns/planning",
    component: _cdc9604c,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-planning___nl"
  }, {
    path: "/reach-campaigns/promotional-positions",
    component: _ff08337e,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-promotional-positions___nl"
  }, {
    path: "/auth/password/reset",
    component: _80a8d204,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset___nl"
  }, {
    path: "/perfect-online-store/series/create",
    component: _77c324ba,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-series-create___nl"
  }, {
    path: "/perfect-online-store/shortcoming/create",
    component: _4f1be98f,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-shortcoming-create___nl"
  }, {
    path: "/reach-campaigns/bms-packages/create",
    component: _7d5436ac,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-packages-create___nl"
  }, {
    path: "/reach-campaigns/bms-packages/info",
    component: _e97f9144,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-packages-info___nl"
  }, {
    path: "/reach-campaigns/bms-positions/create",
    component: _fa6f3f3a,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-positions-create___nl"
  }, {
    path: "/reach-campaigns/campaigns/create",
    component: _74631db6,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-campaigns-create___nl"
  }, {
    path: "/reach-campaigns/campaigns/info",
    component: _586e87d2,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-campaigns-info___nl"
  }, {
    path: "/reach-campaigns/promotional-positions/create",
    component: _9295a6c6,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-promotional-positions-create___nl"
  }, {
    path: "/",
    component: _66e539aa,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/auth/password/reset/:token",
    component: _88e23f04,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset-token___nl"
  }, {
    path: "/auth/invite/:token?",
    component: _056a44e0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-invite-token___nl"
  }, {
    path: "/buying/bms-packages/:uuid",
    component: _19e98dcc,
    pathToRegexpOptions: {"strict":true},
    name: "buying-bms-packages-uuid___nl"
  }, {
    path: "/buying/bms-positions/:uuid",
    component: _fbac2a12,
    pathToRegexpOptions: {"strict":true},
    name: "buying-bms-positions-uuid___nl"
  }, {
    path: "/buying/campaigns/:uuid",
    component: _206c43f5,
    pathToRegexpOptions: {"strict":true},
    name: "buying-campaigns-uuid___nl"
  }, {
    path: "/buying/promotional-positions/:uuid",
    component: _70e41c06,
    pathToRegexpOptions: {"strict":true},
    name: "buying-promotional-positions-uuid___nl"
  }, {
    path: "/buying/series/:uuid",
    component: _84623ca4,
    pathToRegexpOptions: {"strict":true},
    name: "buying-series-uuid___nl"
  }, {
    path: "/buying/suppliers/:supplierUuid",
    component: _f3588eb6,
    pathToRegexpOptions: {"strict":true},
    name: "buying-suppliers-supplierUuid___nl"
  }, {
    path: "/info/magazines/:slug",
    component: _74c376a0,
    pathToRegexpOptions: {"strict":true},
    name: "info-magazines-slug___nl"
  }, {
    path: "/info/news/:slug",
    component: _018313e8,
    pathToRegexpOptions: {"strict":true},
    name: "info-news-slug___nl"
  }, {
    path: "/perfect-online-store/series/:uuid?",
    component: _7982cc43,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-series-uuid___nl"
  }, {
    path: "/perfect-online-store/shortcoming/:uuid",
    component: _7536ec1a,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-shortcoming-uuid___nl"
  }, {
    path: "/reach-campaigns/bms-packages/:uuid",
    component: _00a3491d,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-packages-uuid___nl"
  }, {
    path: "/reach-campaigns/bms-positions/:uuid",
    component: _72a799c6,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-positions-uuid___nl"
  }, {
    path: "/reach-campaigns/budgets/:uuid",
    component: _0ff03c28,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-budgets-uuid___nl"
  }, {
    path: "/reach-campaigns/campaigns/:uuid",
    component: _88109b78,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-campaigns-uuid___nl"
  }, {
    path: "/reach-campaigns/cases/:slug",
    component: _0fa5125e,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-cases-slug___nl"
  }, {
    path: "/reach-campaigns/plans/:uuid",
    component: _5417e248,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-plans-uuid___nl"
  }, {
    path: "/reach-campaigns/promotional-positions/:uuid",
    component: _51d361cc,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-promotional-positions-uuid___nl"
  }, {
    path: "/reach-campaigns/scenarios/:id",
    component: _17f52ae1,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-scenarios-id___nl"
  }, {
    path: "/buying/bms-packages/:uuid?/edit",
    component: _3043ac00,
    pathToRegexpOptions: {"strict":true},
    name: "buying-bms-packages-uuid-edit___nl"
  }, {
    path: "/buying/bms-positions/:uuid?/edit",
    component: _b1850516,
    pathToRegexpOptions: {"strict":true},
    name: "buying-bms-positions-uuid-edit___nl"
  }, {
    path: "/buying/campaigns/:uuid/edit",
    component: _0225e692,
    pathToRegexpOptions: {"strict":true},
    name: "buying-campaigns-uuid-edit___nl"
  }, {
    path: "/buying/promotional-positions/:uuid?/edit",
    component: _25597daf,
    pathToRegexpOptions: {"strict":true},
    name: "buying-promotional-positions-uuid-edit___nl"
  }, {
    path: "/buying/suppliers/:supplierUuid/catalog",
    component: _19bf5ca8,
    pathToRegexpOptions: {"strict":true},
    name: "buying-suppliers-supplierUuid-catalog___nl"
  }, {
    path: "/perfect-online-store/shortcoming/:uuid?/edit",
    component: _f46b681c,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-shortcoming-uuid-edit___nl"
  }, {
    path: "/reach-campaigns/bms-packages/:uuid/edit",
    component: _16acc28f,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-packages-uuid-edit___nl"
  }, {
    path: "/reach-campaigns/bms-packages/:uuid/evaluation",
    component: _11b9cd01,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-packages-uuid-evaluation___nl"
  }, {
    path: "/reach-campaigns/bms-packages/:uuid/finish",
    component: _2e806d58,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-packages-uuid-finish___nl"
  }, {
    path: "/reach-campaigns/bms-positions/:uuid/edit",
    component: _e4118e74,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-bms-positions-uuid-edit___nl"
  }, {
    path: "/reach-campaigns/campaigns/:uuid/edit",
    component: _6bb9cef0,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-campaigns-uuid-edit___nl"
  }, {
    path: "/reach-campaigns/campaigns/:uuid/evaluation",
    component: _8b67f78c,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-campaigns-uuid-evaluation___nl"
  }, {
    path: "/reach-campaigns/campaigns/:uuid/finish",
    component: _448dadde,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-campaigns-uuid-finish___nl"
  }, {
    path: "/reach-campaigns/promotional-positions/:uuid/edit",
    component: _29cf5800,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-promotional-positions-uuid-edit___nl"
  }, {
    path: "/buying/suppliers/:supplierUuid/budgets/create",
    component: _4361bc9a,
    pathToRegexpOptions: {"strict":true},
    name: "buying-suppliers-supplierUuid-budgets-create___nl"
  }, {
    path: "/buying/suppliers/:supplierUuid/plans/create",
    component: _a410b084,
    pathToRegexpOptions: {"strict":true},
    name: "buying-suppliers-supplierUuid-plans-create___nl"
  }, {
    path: "/buying/suppliers/:supplierUuid/plans/:planUuid?",
    component: _a69bd2de,
    pathToRegexpOptions: {"strict":true},
    name: "buying-suppliers-supplierUuid-plans-planUuid___nl"
  }, {
    path: "/buying/suppliers/:supplierUuid/budgets/:budgetUuid?/edit",
    component: _6c80f8f8,
    pathToRegexpOptions: {"strict":true},
    name: "buying-suppliers-supplierUuid-budgets-budgetUuid-edit___nl"
  }, {
    path: "/buying/:slug",
    component: _b4536ae4,
    pathToRegexpOptions: {"strict":true},
    name: "buying-slug___nl"
  }, {
    path: "/campaign-evaluation/:uuid?",
    component: _2bd339fd,
    pathToRegexpOptions: {"strict":true},
    name: "campaign-evaluation-uuid___nl"
  }, {
    path: "/info/:slug",
    component: _3af18e40,
    pathToRegexpOptions: {"strict":true},
    name: "info-slug___nl"
  }, {
    path: "/insights/:slug",
    component: _2782b04d,
    pathToRegexpOptions: {"strict":true},
    name: "insights-slug___nl"
  }, {
    path: "/perfect-online-store/:slug?",
    component: _6bfc24ce,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-online-store-slug___nl"
  }, {
    path: "/reach-campaigns/:slug?",
    component: _db639246,
    pathToRegexpOptions: {"strict":true},
    name: "reach-campaigns-slug___nl"
  }, {
    path: "/:slug",
    component: _6a527c3a,
    pathToRegexpOptions: {"strict":true},
    name: "slug___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
