import {
  ROLE_BUYER_ADMIN,
  ROLE_BUYER,
  ROLE_ECOMMERCE,
  ROLE_SUPPLIER,
  ROLE_MARKETEER,
} from '~/plugins/constants';

export const getters = {
  isSupplier(state, getters, rootState) {
    return rootState.auth.loggedIn && rootState.auth.user.role_id === ROLE_SUPPLIER && !!rootState.auth.user.supplier;
  },
  isBuyer(state, getters, rootState) {
    return rootState.auth.loggedIn && [ROLE_BUYER_ADMIN, ROLE_BUYER].includes(rootState.auth.user.role_id);
  },
  isBuyerAdmin(state, getters, rootState) {
    return rootState.auth.loggedIn && rootState.auth.user.role_id === ROLE_BUYER_ADMIN;
  },
  isEcommerce(state, getters, rootState) {
    return rootState.auth.loggedIn && rootState.auth.user.role_id === ROLE_ECOMMERCE;
  },
  isTraffic(state, getters, rootState) {
    return rootState.auth.loggedIn && rootState.auth.user.role_id === ROLE_MARKETEER;
  },
  isConnect(state, getters, rootState) {
    return rootState.auth.loggedIn && rootState.auth.user.is_connect;
  },
};
