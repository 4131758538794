
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    modelConfig: {
      mask: 'DD-MM-YYYY',
    },
    attrs: [
      {
        key: 'today',
        highlight: {
          class: 'today',
        },
        dates: new Date(),
      },
    ],
  }),
  computed: {
    pickerAttributes() {
      return Object.assign(this.context.attributes, {id: null, class: null});
    },
    isDisabled() {
      return 'disabled' in this.context.attributes;
    },
    date: {
      get() {
        return this.$moment(this.context.model, 'YYYY-MM-DD').format('DD-MM-YYYY');
      },
      set(value) {
        this.context.model = this.$moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
      },
    },
  },
  methods: {
    parseDate(value) {
      return this.$moment(value, 'DD-MM-YYYY', true).isValid();
    },
  },
};
