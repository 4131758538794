
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    values: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      },
    },
    attributes() {
      return Object.assign(this.context.attributes, {id: null, class: null});
    },
    options() {
      return this.context.options.map((item) => {
        return {value: item.value, label: item.label};
      });
    },
    optionValues() {
      return this.context.options.map((item) => {
        return item.value;
      });
    },
  },
};
