import { render, staticRenderFns } from "./MobileMenu.vue?vue&type=template&id=3a8699ab&"
import script from "./MobileMenu.vue?vue&type=script&lang=js&"
export * from "./MobileMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MobileMenuSubmenu: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/base/MobileMenuSubmenu.vue').default,IconsChevron: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/icons/chevron.vue').default,Button: require('/data/www/connectbybidfood.nl/bidfood-connect/nuxt/components/formulate/Button.vue').default})
