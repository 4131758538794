
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeSubmenu() {
      this.$emit('closeSubmenu');
    },
  },
};
