export const ROLE_DEV = 6;
export const ROLE_CONNECT_ADMIN = 5;
export const ROLE_CONNECT_MANAGER = 4;
export const ROLE_COMMUNICATION = 3;
export const ROLE_ECOMMERCE = 10;
export const ROLE_MARKETEER = 9;
export const ROLE_BUYER_ADMIN = 7;
export const ROLE_BUYER = 8;
export const ROLE_SUPPLIER = 2;

export const ROLE_LABELS = {
  2: 'Leverancier',
  3: 'Communicatie medewerker',
  4: 'Connect Manager',
  5: 'Connect Admin',
  6: 'Developer',
  7: 'Buyer Admin',
  8: 'Buyer',
  9: 'Traffic / DG-marketeer',
  10: 'E-commerce medewerker',
  11: 'Connect assistent',
  12: 'Applicatiebeheerder',
};

// campaigns and salespositions
export const STATUS_UNFINISHED = 'unfinished';
export const STATUS_CONCEPT = 'concept';
export const STATUS_AWAITING_DG_REVIEW = 'awaiting_dg_review';
export const STATUS_AWAITING = 'awaiting';
export const STATUS_PENDING = 'pending';
export const STATUS_ACCEPTED = 'accepted';
export const STATUS_CANCELED = 'canceled';
export const STATUS_ACTION_REQUIRED = 'action_required';
export const STATUS_AWAITING_MATERIALS = 'awaiting_materials';
export const STATUS_AWAITING_PREVIEW = 'awaiting_preview';
export const STATUS_AWAITING_FEEDBACK = 'awaiting_feedback';
export const STATUS_PLANNED = 'planned';
export const STATUS_PREPARED = 'prepared';
export const STATUS_STARTED = 'started';
export const STATUS_EXPIRED = 'expired';
export const STATUS_ENDED = 'ended';
export const STATUS_REJECTED = 'rejected';
export const STATUS_REQUESTED = 'requested';
export const STATUS_COMPLETED = 'completed';
// budget requests
export const STATUS_BUDGET_COUNTER_SUPPLIER = 'counter_supplier';
export const STATUS_BUDGET_COUNTER_BUYER = 'counter_buyer';
// change requests
export const STATUS_CHANGE_REQUEST_NEW = 'new';
export const STATUS_CHANGE_REQUEST_ACCEPTED = STATUS_ACCEPTED;
export const STATUS_CHANGE_REQUEST_REJECTED = STATUS_REJECTED;

export const SALES_POSITION = 'salesPosition';

export const REQUIREMENTS_NONE = 'Geen';

export const DEFAULT_POLL_INTERVAL = 60 * 1000; // in ms

export const DURATION_2_WEEKS = 'Maximaal 2 weken';
export const DURATION_1_WEEK = 'Maximaal 1 week';

export const CHART_COLORS = {
  'Catering, Kantines en Retail': '#93D8EE',
  Fastservice: '#26B0DC',
  Overig: '#DEDFE0',
  Restauratief: '#76C067',
  Zorg: '#BBE0B3',
};

export const EVALUATION_CARD_TEXT = 'text';
export const EVALUATION_CARD_TABLE = 'table';
export const EVALUATION_CARD_IMAGE = 'image';

export const SCENARIO_TYPES = {
  CAMPAIGN: 'Campagne',
  MAGAZINE: 'Magazine',
  ACTIEFOLDER: 'Actiefolder',
  SALES_POSITION: 'Online salespositie',
};
