
import {mapState} from 'vuex';

export default {
  data() {
    return {
      open: false,
      profilePanel: false,
      hours: this.$moment().format('HH'),
      interval: null,
    };
  },
  computed: {
    ...mapState(['settings']),
    greetings() {
      if (this.hours < 6) {
        return 'Goedenacht';
      } else if (this.hours < 12) {
        return 'Goedemorgen';
      } else if (this.hours < 18) {
        return 'Goedemiddag';
      } else {
        return 'Goedenavond';
      }
    },
    welcome() {
      return this.settings.find(setting => setting.key === 'welkomsttekst')?.value;
    },
  },
  watch: {
    $route() {
      this.open = false;
      this.$nextTick(() => {
        this.checkForTitle();

        setTimeout(() => {
          this.checkForTitle();
        }, 100);
        setTimeout(() => {
          this.checkForTitle();
        }, 500);
        setTimeout(() => {
          this.checkForTitle();
        }, 1000);
      });
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.hours = this.$moment().format('HH');
    }, 10000);

    this.checkForTitle();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      await this.$apolloProvider.defaultClient.cache.reset();
      this.profilePanel = false;
    },
    clickOutside() {
      if (this.profilePanel) {
        this.profilePanel = false;
      }
    },
    checkForTitle() {
      const component = this.$refs.page && this.$refs.page.$children[0];

      this.$store.commit('breadcrumbs/SET_TITLE', component?.$metaInfo?.title || null);
    },
  },
};
